<template>
  <button
    v-tippy="{ placement: 'left' }"
    class="button has-text-info"
    title="Download CSV"
    :disabled="loading"
    @click.prevent="downloadCSV"
  >
    <span class="icon">
      <i class="fal fa-file-csv" />
    </span>
  </button>
</template>

<script>
import * as CSVService from '../services/csv.service'
export default {
  name: 'DownloadCSVButton',
  props: {
    columns: {
      type: Array,
      required: true
    },
    getEntries: {
      type: Function,
      required: true
    },
    postfix: {
      type: String,
      default: 'export'
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async downloadCSV() {
      this.loading = true

      try {
        const entries = await this.getEntries()
        await CSVService.downloadCSV(this.columns, entries, this.postfix)
      } catch {
        this.$notify('Something went wrong while downloading the CSV')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
