import saveAs from 'file-saver'
import moment from 'moment'
import { get } from 'lodash'

const NEW_LINE = '\n'

const generateCSV = (columns, entries, separator = ',') => {
  const header = columns.map((column) => column.label).join(separator)

  const rows = entries.map((entry) =>
    columns
      .map((column) => {
        const value = get(entry, column.path)

        if (value === null || value === undefined) {
          return ''
        }

        switch (column.format) {
          case 'date':
            return moment(value).format('DD/MM/YYYY')
          default:
            return value
        }
      })
      .join(separator)
  )

  return [header, ...rows].join(NEW_LINE)
}

export const downloadCSV = (columns, entries, postfix) => {
  const response = generateCSV(columns, entries)
  const blob = new Blob([response], { type: 'text/csv;charset=utf-8' })
  saveAs(blob, `${moment().format('DD-MM-YYYY')}-${postfix}.csv`)
}
